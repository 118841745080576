const state = {
    selectedVariantId: null,

    totalCartsQty: 0,
    wantedQty: 1,

    carts: Array(),
    optionSelectionModel: Array(),

    inStock: false,
    allOptionSelected: false,
    isLoading: false,
    isLoadingAdd: false
}

const mutations = {
    setCarts(state, carts) {
        state.carts = carts;
    },
    setWantedQty(state, wantedQty) {
        state.wantedQty = wantedQty;
    },
    setTotalCartLines(state, totalCartsQty) {
        state.totalCartsQty = totalCartsQty;
      },
    incWantedQty(state, variant) {
        state.wantedQty = parseInt(state.wantedQty) + 1;
        if (state.wantedQty <= variant.stockQty) {
            state.inStock = true;
        } else {
            state.inStock = false;
        }
    },
    decWantedQty(state, variant) {
        if (state.wantedQty > 1) {
            state.wantedQty = parseInt(state.wantedQty) - 1;
        }

        if (state.wantedQty <= variant.stockQty) {
            state.inStock = true;
        } else {
            state.inStock = false;
        }
    },
    checkStock(state, variant) {
        if (variant && state.wantedQty <= variant.stockQty) {
            state.inStock = true;
        } else {
            state.inStock = false;
        }
    },
    setOptionSelectionModel(state, data) {
        var optionSelectionModel = data.optionSelectionModel;
        state.optionSelectionModel = optionSelectionModel;
        state.selectedVariantId = null;

        if (data.productType == 'SIMPLE') {
            state.allOptionSelected = true;
        } else {
            state.allOptionSelected = optionSelectionModel.length == 0;
        }
    },
    selectVariant(state, { optionTypeIdx, variantValue }) {
        var optionSelectionModel = [...state.optionSelectionModel]
        var tmp = optionSelectionModel[optionTypeIdx];

        state.allOptionSelected = optionTypeIdx == (optionSelectionModel.length - 1);

        tmp.forEach(element => {
            if (element.value == variantValue) {
                if (!element.selected) {
                    element.selected = true;
                    if (element.subVariant != null) {
                        element.subVariant.forEach(child => {
                            child.selected = false;
                        });

                        optionSelectionModel[optionTypeIdx + 1] = element.subVariant;
                        for (var i = optionTypeIdx + 2; i < optionSelectionModel.length; i++) {
                            optionSelectionModel[i] = [];
                        }
                    } else {
                        state.selectedVariantId = element.id;
                    }
                }
            } else {
                element.selected = false;
            }
        });

        state.optionSelectionModel = optionSelectionModel;
    },
    setIsLoading(state, isLoading) {
        state.isLoading = isLoading
    },
    setIsLoadingAdd(state, isLoadingAdd) {
        state.isLoadingAdd = isLoadingAdd
    }
}

const actions = {
    async getCartLines({ rootState, commit, dispatch }) {
        try {
            commit('setIsLoading', true)
            const context = rootState.context

            let res = await context.$axios.get('/carts/lines', {
                params: {
                    kind: rootState.tokenDecoded.shoppingFeature || 'SHOPPING',
                }
            })

            var content = res.data.cartlines.content;
            commit('setTotalCartLines', res.data.totalQty);

            res.data.content = [];

            for (var line in content) {
                var cartLine = content[line];
                cartLine.cartLine.optionProperties.base_size = cartLine.properties.find(property => property.enumerationId == 'ApparelSize');
                cartLine.cartLine.optionProperties.base_color = cartLine.properties.find(property => property.enumerationId == 'Color');
                cartLine.cartLine.displayImage = cartLine.displayImage ? cartLine.displayImage.src : null;
                cartLine.cartLine.tagIds = cartLine.tagIds;
                res.data.content.push(cartLine.cartLine);
            }

            await commit('setCarts', res.data.content);
            commit('setIsLoading', false)
        } catch (error) {
            commit('setIsLoading', false)
            console.log("error-get-cart-lines", error)
        }
    },
    async addProduct({ rootState, state, commit }, item) {
        try {
            commit('setIsLoadingAdd', true)
            const context = rootState.context

            const body = {
                productId: item.productId,
                variantId: item.variantId,
                kind: rootState.tokenDecoded.shoppingFeature || 'SHOPPING',
                clientDevice: 'DESKTOP',
                wanted: state.wantedQty.toString()
            };

            const res = await context.$axios.post(`carts/lines`, body);
            commit('setIsLoadingAdd', false)
            return res
        } catch (error) {
            commit('setIsLoadingAdd', false)
            console.error("add-product", error)
        }
    },
    initSelectionModel({ commit }, product) {
        var optionSelectionModel = [];
        if (product.type == 'VARIED') {
            var optionTypeCount = product.optionTypes.length;

            var lowestLevelSubVariant = [];

            function setupChildVariant(optIds, optTypeIdx) {
                var availableInStockExists = false;
                var variants = product.optionTypes[optTypeIdx].options.map(val => {
                    var tmp = {
                        selected: false,
                        value: val.values[0].value,
                        displayValue: val.values[0].displayValue
                    }

                    var tmpOptIds = {};
                    tmpOptIds['base_' + product.optionTypes[optTypeIdx].id] = val.values[0].value;
                    tmpOptIds = {
                        ...tmpOptIds,
                        ...optIds
                    };
                    if ((optTypeIdx + 1) < optionTypeCount) {
                        var recursiveRetval = setupChildVariant(tmpOptIds, optTypeIdx + 1);
                        tmp.subVariant = recursiveRetval.variants;
                        tmp.availableInStockExists = recursiveRetval.availableInStockExists;
                        availableInStockExists = availableInStockExists || recursiveRetval.availableInStockExists;
                    } else {
                        tmp.optionIds = tmpOptIds;
                        var variantInfo = product.variants.find(variantInfo => {
                            var allMatches = true;
                            for (var optPropKey in variantInfo.optionProperties) {
                                allMatches = allMatches && tmp.optionIds[optPropKey] ==
                                    variantInfo.optionProperties[optPropKey][0].value;
                            }

                            return allMatches;
                        });

                        tmp.availableInStockExists = false;
                        if (variantInfo != null) {
                            tmp.id = variantInfo.id;
                            tmp.availableInStockExists = variantInfo.qty > 0.0;
                            tmp.stockQty = variantInfo.qty;
                            availableInStockExists = availableInStockExists || variantInfo.qty > 0.0;
                        }
                        lowestLevelSubVariant.push(tmp);
                    }

                    return tmp;
                });
                return {
                    variants,
                    availableInStockExists
                };
            }

            for (var i = 0; i < optionTypeCount; i++) {
                optionSelectionModel.push([]);
            }
            optionSelectionModel[0] = setupChildVariant({}, 0).variants;
        } else if (product.type == 'SIMPLE') {
            var tmp = {
                selected: true,
                value: '',
                displayValue: '',
                id: product.id,
                availableInStockExists: product.qty > 0.0,
                stockQty: product.qty
            }
            optionSelectionModel.push([tmp]);
        }

        commit('setOptionSelectionModel', {
            optionSelectionModel,
            productType: product.type
        });
    },
    chooseVariants({ commit }, { optionTypeIdx, variantValue }) {
        commit('selectVariant', { optionTypeIdx, variantValue });
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}