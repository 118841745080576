<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card theme="dark" rounded="lg">
                <v-card-title class="justify-space-between align-center">
                    <div class="mt-2 text-h5 text-medium-emphasis ps-2">Daftar</div>
                    <!-- <small class="text-medium-emphasis ps-2" style="font-size:14px">Gunakan akun yang terdaftar di www.tuneeca.com</small> -->
                </v-card-title>
                <v-card-text>
                    <v-text-field required label="Email" type="email" v-model="email"></v-text-field>
                    <v-text-field required label="No Handphone" type="number" v-model="mobileNumber"></v-text-field>
                    <v-text-field required label="Nama" v-model="username"></v-text-field>
                    <v-text-field required label="Password" type="password" v-model="password"></v-text-field>
                    <v-row class="mt-4">
                        <v-col cols="12" class="mt-0 pt-0">
                            <v-btn class="btn-primary" rounded block variant="flat" text color="primary" @click="register()"
                                :loading="isLoading" style="background-color: #694177 !important;">
                               <v-icon class="mr-1">mdi-account</v-icon>  Daftar
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="mt-0 pt-0">
                            <v-btn rounded block variant="flat" color="white" text  @click="getGoogleLink()" :loading="isLoading">
                                   <v-icon class="mr-1">mdi-google</v-icon> Daftar Dengan Google
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>


                <v-divider class=""></v-divider>
                <v-card-actions>
                    <v-btn text @click="dialog = false, resetDialog()">
                        Keluar
                    </v-btn>
                    <v-spacer></v-spacer>
                    

                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import {
        Buffer
    } from 'buffer';

    export default {
        name: "Dialog Register",

        data: () => {
            return {
                email: String(),
                mobileNumber: String(),
                username: String(),
                password: String(),

                dialog: false,
                isLoading: false,
            }
        },

        methods: {
            showDialog() {
                this.dialog = true
            },

            async getGoogleLink() {
                try {
                    const json = JSON.stringify({
                        location: window.location
                    });

                    const base64String = Buffer.from(json).toString("base64");

                    const response = await this.$axios.get("/users/oauth/google", {
                        params: {
                            callback: window.location.protocol.concat("//").concat(window.location.hostname)
                                .concat("/"),
                            state: base64String,
                        },
                    });

                    window.location = response.data.url;
                } catch (error) {

                    if (error.response && error.response.data) {
                        this.$toast.error(error.response.data.message)
                    } else {
                        this.$toast.error('Error Daftar Google')
                    }
                }
            },

            async register() {
                try {
                    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                    if ((!this.email || this.email == "") && (!this.mobileNumber || this.mobileNumber == "")) {
                        this.$toast.error("email/No. Handphone tidak boleh kosong");
                        return;
                    }
                    if (this.email && !emailPattern.test(this.email)) {
                        this.$toast.error("email tidak valid");
                        return;
                    }
                    if (!this.username || this.username == "") {
                        this.$toast.error("name tidak boleh kosong");
                        return;
                    }
                    if (!this.password || this.password == "") {
                        this.$toast.error("password tidak boleh kosong");
                        return;
                    }

                    this.isLoading = true

                    const res = await this.$axios.post("/accounts", {
                        name: this.username,
                        email: this.email.toLowerCase().trim(),
                        mobileNumber: this.mobileNumber,
                        password: this.password
                    });

                    this.setCookie(res)
                    this.dialog = false
                    this.$toast.success('Berhasil mendaftar')
                    this.resetDialog()

                    this.isLoading = false
                } catch (error) {
                    this.isLoading = false

                    if (error.response && error.response.data) {
                        this.$toast.error(error.response.data.message)
                    } else {
                        this.$toast.error('Error Daftar')
                    }
                }
            },

            setCookie: function (response) {
                // const expireTimes = 60 * 60 * 24 * process.env.VUE_APP_COOKIE_EXPIRES

                this.$cookies.set('bearer', response.data.jwt.token);
                this.$cookies.set('refreshTokenId', response.data.jwt.refreshTokenId);
                this.$cookies.set('refreshToken', response.data.jwt.refreshToken);
                this.$cookies.set('refreshTokenValidUntil', response.data.jwt.refreshTokenValidUntil);

                this.$store.commit('setToken', response.data.jwt.token)
            },

            resetDialog() {
                this.username = String()
                this.email = String()
                this.password = String()
                this.mobileNumber = String()
            }
        }
    }
</script>

<style>
.bg-primary{
    background-color:#694177 !important;
}

.btn-primary{
    background-color:#694177 !important;
}
</style>