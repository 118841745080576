<template>
    <div>
        
    </div>
</template>

<script>

export default {

    name: 'MyFlipbookOld',

    async mounted() {
        const query = this.$route.query

        if(query.slug) {
            this.$router.push(`/${query.slug}`)
        } else {
            this.$router.push({
                name: 'Home'
            })

            this.$toast.error('Slug tidak ditemukan!')
        }
    },

    methods: {

    }
}

</script>