import { createStore } from 'vuex'
import { jwtDecode } from "jwt-decode";
import { sha256 } from 'js-sha256';

import modules from './modules'

export default createStore({ 
    state () {
        return {
            customerType: "retail",

            token: null,

            context: Object(),
            tokenDecoded: Object(),
            refreshToken: Object(),
            profile: Object(),
            address: Object(),
            analytics: Object()
        }
    },

    mutations: {
        setContext(state, context) {
            state.context = context
        },
        setToken(state, token) {
            state.token = token

            if(token) {
                const decoded = jwtDecode(token);
                state.tokenDecoded = decoded

                if(decoded.role == null || decoded.role == 'member_common' || decoded.role == 'common' || decoded.role == 'null') {
                    state.customerType = 'retail'
                  } else {
                    state.customerType = 'reseller'
                  }
            } else {
                state.tokenDecoded = Object()
                state.customerType = 'retail'
            }
        },
        setRefreshToken(state, refreshToken) {
            state.refreshToken = refreshToken
        },
        setProfile(state, profile) {
            if (profile.mobileNumbers.length <= 0) {
                profile.mobileNumbers = [{
                    phoneNumber: "",
                    primary: true
                }]
            }

            state.profile = profile;
        },
        setAddress(state, address) {
            state.address = address;
        },
        setAnalytics(state, analytics) {
            state.analytics = {
                googleId: analytics.GOOGLE_ANALYTICS_FLIPBOOK_ID,
                facebookDatasetId: analytics.FACEBOOK_PIXEL_FLIPBOOK_DATASET,
                facebookToken: analytics.FACEBOOK_PIXEL_FLIPBOOK_TOKEN
            }
        },
    },

    actions: {
        async pageInit({ state, dispatch }) {
            if(!state.analytics.googleId) {
                await dispatch('getAnalytics')
            }

            if(state.token) {
                try {
                    if(!state.profile.id) {
                        dispatch('getProfile')
                        dispatch('getAddress')
                    }
                } catch (error) {
                    console.log("error-page-init", error)
                }
            }
        },
        async getProfile({ state, commit }) {
            const context = state.context

            try {
                const { data } = await context.$axios.get('/accounts');

                commit('setProfile', data);
            } catch (error) {
                console.log("error-get-profile", error)
            }
        },
        async getAddress({ state, commit }) {
            const context = state.context

            try {
                const { data } = await context.$axios.get('/accounts/addresses');

                if(data) {
                    const sortAddress = data.sort((a, b) => b.primary - a.primary)

                    commit('setAddress', sortAddress);
                }
            } catch(error) {
                console.log("error-get-address", error)
            }
        },
        async getAnalytics({ state, commit }) {
            const context = state.context

            try {
                const { data } = await context.$axios.get('/pages/flipbook/init');

                commit('setAnalytics', data)
            } catch(error) {
                console.log("error-api-ini", error)
            }
        },
        async actPostEventFbConversion({ state }, data) {
            try {
                const context = state.context

                const myProfile = state.profile
                const myAddress = state.address
          
                const hashString = (string) => {
                    return sha256(string.toLowerCase())
                }
          
                const payload = {
                    ...data,
                    event_time: Math.round((new Date()).getTime() / 1000)
                }

                if (state.token) {
                    const user_data = {
                        fn: [hashString(myProfile.name)],
                        subscription_id: myProfile.customerRole
                    }
            
                    if (myProfile.birthDate) user_data.db = [hashString(myProfile.birthDate)]
            
                    if (myProfile.emails && myProfile.emails.length > 0) {
                        const obj = myProfile.emails.find(e => e.primary == true)
                
                        if (obj && Object.keys(obj).length > 0) user_data.em = [hashString(obj.email)]
                    }
            
                    if (myProfile.mobileNumbers && myProfile.mobileNumbers.length > 0) {
                        const obj = myProfile.mobileNumbers.find(e => e.primary == true)
                
                        if (obj && Object.keys(obj).length > 0) user_data.ph = [hashString(obj.phoneNumber)]
                    }
            
                    if (myProfile.gender == 'FEMALE') user_data.ge = [hashString('f')]
                    if (myProfile.gender == 'MALE') user_data.ge = [hashString('m')]
            
                    if (myAddress && myAddress.length > 0) {
                        const obj = myAddress.find(e => e.primary == true)
                
                        if (obj && Object.keys(obj).length > 0) {
                            if (obj.countryCode) user_data.country = [hashString(obj.countryCode)]
                            if (obj.city) user_data.ct = [hashString(obj.city)]
                            if (obj.postalCode) user_data.zp = [hashString(obj.postalCode)]
                            if (obj.street) user_data.st = [hashString(obj.street)]
                        }
                    }
            
                    payload.user_data = user_data

                } else {

                    const user_data = {
                        fn: [hashString('Guest')],
                        em: [hashString('guest@gmail.com')],
                        country: [hashString('id')]
                    }
            
                    payload.user_data = user_data
                }

                if (context.$cookies.get('_fbp')) payload.user_data.fbp = context.$cookies.get('_fbp')
                if (context.$cookies.get('_fbc')) payload.user_data.fbc = context.$cookies.get('_fbc')
                if (state.clientIp) payload.user_data.client_ip_address = state.clientIp

                state.analytics = {
                    googleId: analytics.GOOGLE_ANALYTICS_FLIPBOOK_ID,
                    facebookDatasetId: analytics.FACEBOOK_PIXEL_FLIPBOOK_DATASET,
                    facebookToken: analytics.FACEBOOK_PIXEL_FLIPBOOK_TOKEN
                }

                if (state.analytics.facebookDatasetId && state.analytics.facebookToken) {
                    await context.$axios.post(`https://graph.facebook.com/v19.0/${state.analytics.facebookDatasetId}/events`, {
                        data: [payload]
                    }, {
                        params: {
                            access_token: state.analytics.facebookToken
                        }
                    })
                }
            } catch (error) {
                console.log("error-facebook-pixel", error)
            }
        },
    },

    modules
})