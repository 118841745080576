<template>
    <div class="NotFound text-center">
        <div class="container">
            <h1><v-icon>mdi-emoticon-sad</v-icon></h1>
            <h1>Oops!</h1>
            <div class="clearfix"></div>
            Katalog Tidak Ditemukan!
        </div>
    </div>    
</template>
<script>

export default {
    name: 'NotFound',
}

</script>

<style>

.NotFound{
  margin: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.NotFound .container{
    /* position: relative; */
}

.NotFound h1{
    font-size: 6rem;
}
</style>
