<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ccc;
  background-color: #333;
  overflow: hidden;
}

.opacity {
  background-color: #333;
  position: absolute;
  width: 100%;
  opacity: 60%;
  height: 100%;
  z-index: 1000;
}

.v-step{
  border-radius: 10px !important;
}
</style>
