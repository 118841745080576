const state = {
    product: Object(),

    isLoading: false
}

const mutations = {
    setProduct(state, product) {
        state.product = product
    },
    setIsLoading(state, isLoading) {
        state.isLoading = isLoading
    }
}

const actions = {
    async getDetail({ rootState, commit, dispatch }, product) {
        const context = rootState.context

        try {
            commit('setIsLoading', true)    

            const res = await context.$axios.get(`/products/${product.promotedProducts[0].id}`, {
                params: {
                    statuses: null
                }
            });

            dispatch('Cart/initSelectionModel', res.data, { root:true });
            commit('setProduct', res.data)
            
            commit('setIsLoading', false)
        } catch (error) {
            commit('setProduct', Object())
            commit('setIsLoading', false)

            if(error.response) {
                if(error.response.status == 404) {
                    let text = `${product.promotedProducts[0].name} - ${product.promotedProducts[0].localSku}\n`
                    text += process.env.VUE_APP_COMMERCE_WEB_URL + `/id/${product.promotedProducts[0].slug}\n`
                    text += `\nTolong ingatkan saya produk ini jika sudah launching`
                    const qs = new URLSearchParams()
                    qs.append("text", text)

                    const params = qs.toString()
                    const number = process.env.VUE_APP_WA_NUMBER

                    window.open(`http://wa.me/${number}?${params}`, '_blank');
                } else {
                    context.$toast.error(error.response.data.message)
                }
            } else {
                console.log("error-detail-product", error)
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}