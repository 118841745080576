<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card theme="dark" rounded="lg">
                <v-card-title class="justify-space-between align-center">
                    <div class="mt-2 text-h5 text-medium-emphasis ps-2">Login</div>
                    <small class="text-medium-emphasis ps-2" style="font-size:14px">Gunakan akun yang terdaftar di www.tuneeca.com</small>
                    <!-- <v-btn icon dark @click="hideDialog()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn> -->
                    
                </v-card-title>
                <v-card-text>
                    <div class="mb-5">
                        <v-text-field required
                            label="Email / Username" 
                            v-model="username"
                            v-on:keyup.enter="login()"
                        ></v-text-field>
                        <v-text-field required
                            label="Password" 
                            type="password" 
                            v-model="password"
                            v-on:keyup.enter="login()"
                        ></v-text-field>
                    </div>
                    <v-row>
                        <v-col cols="12" class="mt-0 pt-0">
                            <v-btn class="btn-primary" rounded block variant="flat" text color="primary" @click="login()" :loading="isLoadingLogin"
                                :disabled="isLoadingLogin || isLoadingLoginGoogle" style="background-color: #694177 !important;">
                                <v-icon class="mr-1">mdi-login</v-icon>
                                Login
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="mt-0 pt-0">
                            <v-btn rounded block variant="flat" color="white" text @click="getGoogleLink()" :loading="isLoadingLoginGoogle"
                                :disabled="isLoadingLogin || isLoadingLoginGoogle">
                                <v-icon class="mr-1">mdi-google</v-icon>
                                Login Dengan Google
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider class=""></v-divider>
                <v-card-actions>
                    <v-btn text @click="dialog = false, resetDialog()">
                        Keluar
                    </v-btn>
                    <v-spacer></v-spacer>
                     <v-btn text @click="register()">
                        Daftar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { Buffer } from 'buffer';

export default {
    name: "Dialog Login",

    data: () => {
        return {
            username: String(),
            password: String(),

            dialog: false,
            isLoadingLogin: false,
            isLoadingLoginGoogle: false
        }
    },

    methods: {
        showDialog() {
            this.resetDialog()

            this.dialog = true
        },

        register() {
            this.dialog = false
            this.$emit('open-register')
        },

        async login() {
            if (!this.username || this.username == "") {
                this.$toast.error("Email atau Username");
                return;
            }

            if (!this.password || this.password == "") {
                this.$toast.error("Password tidak boleh kosong");
                return;
            }

            try {
                this.isLoadingLogin = true

                const body = {
                    "username": this.username,
                    "password": this.password,
                    "rememberMe": false
                }
    
                const response = await this.$axios.post(`/users/`, body)
    
                this.setCookie(response)
                this.isLoadingLogin = false
                this.dialog = false
                this.$toast.success('Berhasil melakukan login')

                this.username = String()
                this.password = String()
            } catch (error) {
                this.isLoadingLogin = false

                if(error.response && error.response.data) {
                    this.$toast.error(error.response.data.message)
                } else {
                    this.$toast.error('Username atau Password salah!')
                }
            }
        },

        async getGoogleLink() {
            try {
                this.isLoadingLoginGoogle = true

                const json = JSON.stringify({
                    location: window.location
                });

                const base64String =  Buffer.from(json).toString("base64");

                const response = await this.$axios.get("/users/oauth/google", {
                    params: {
                        callback: window.location.protocol.concat("//").concat(window.location.hostname).concat("/"),
                        state: base64String,
                    },
                });

                window.location = response.data.url;

                this.isLoadingLoginGoogle = false
            } catch (error) {
                this.isLoadingLoginGoogle = false
                if(error.response && error.response.data) {
                    this.$toast.error(error.response.data.message)
                } else {
                    this.$toast.error('Error Daftar Google')
                }
            }
        },

        setCookie: function (response) {
            // const expireTimes = 60 * 60 * 24 * process.env.VUE_APP_COOKIE_EXPIRES

            this.$cookies.set('bearer', response.data.token);
            this.$cookies.set('refreshTokenId', response.data.refreshTokenId);
            this.$cookies.set('refreshToken', response.data.refreshToken);
            this.$cookies.set('refreshTokenValidUntil', response.data.refreshTokenValidUntil);

            this.$store.commit('setToken', response.data.token)
        },

        resetDialog() {
            this.username = String()
            this.password = String()
        }
    }
}
</script>

<style>
.bg-primary{
    background-color:#694177 !important;
}

.btn-primary{
    background-color:#694177 !important;
}
</style>
